import { Box, Center, Flex, Grid, Image } from "@chakra-ui/react"
import React from "react"
import { testimonialsContents } from "../../utils/descriptions"
import { AiFillStar } from "react-icons/ai"

const HomeTestimonial = () => {
  const [currentIndex, setCurrentIndex] = React.useState(0)

  return (
    <Box mx={{ "2xl": "11rem", xl: "8rem", lg: "5rem", base: "1rem" }} mt="16">
      <Center>
        <Box fontWeight={600} fontSize={20}>
          Testimonials
        </Box>
      </Center>
      <Box
        fontSize={{ xl: 35, lg: 30, sm: 27, base: 20 }}
        fontWeight={700}
        color="black"
      >
        <Center>Highly Appreciated Software</Center>
      </Box>
      <Box mx={{ "2xl": "9rem", xl: "1rem", base: "0" }}>
        <Grid
          templateColumns={{ xl: "repeat(2, 1fr)", base: "repeat(1, 1fr)" }}
          gap={{ "2xl": 16, xl: 12, lg: 8, sm: 4, base: 0 }}
          color="white"
        >
          <Box my={{ xl: "16", base: "12" }}>
            {testimonialsContents.map((testimon, boxIndex) => (
              <Box
                key={testimon.name}
                w={{ lg: "90%", base: "100%" }}
                rounded={"lg"}
                bgGradient={
                  currentIndex === boxIndex
                    ? "linear(to-l, #8227EA, #3C50D4)"
                    : ""
                }
                mb="3"
                shadow={currentIndex === boxIndex ? "md" : ""}
                cursor={"pointer"}
                borderWidth={"1px"}
                onClick={() => setCurrentIndex(boxIndex)}
              >
                <Box
                  p="5"
                  color={currentIndex === boxIndex ? "white" : "black"}
                >
                  <Flex>
                    <Image
                      src={testimon.profile}
                      borderRadius={"full"}
                      boxSize="70px"
                      mb="0"
                    />

                    <Box ml="8" my="auto">
                      <Box fontSize={18} fontWeight={600}>
                        {testimon.name}
                      </Box>
                      <Box fontSize={"14"}>
                        <Flex>
                          {testimon.prof.map((profs, index) => (
                            <span key={profs + Math.random()}>
                              {`${index === 0 ? "" : ", "}`}
                              {`${profs}`}
                            </span>
                          ))}
                        </Flex>
                      </Box>
                    </Box>
                  </Flex>
                </Box>
              </Box>
            ))}
          </Box>
          <Box my={{ xl: "16", base: "0" }}>
            <Box color="black" fontSize={25} fontWeight={700} mt="5">
              {testimonialsContents[currentIndex].descTitle}
            </Box>
            <Box>
              <Flex gap="2">
                <AiFillStar color="#E7B122" />
                <AiFillStar color="#E7B122" />
                <AiFillStar color="#E7B122" />
                <AiFillStar color="#E7B122" />
                <AiFillStar color="#E7B122" />
              </Flex>
            </Box>
            <Box color="black" mt="6" fontSize="15" fontWeight={500}>
              {testimonialsContents[currentIndex].descComment1}
            </Box>
            {testimonialsContents[currentIndex].descComment2 ? (
              <Box color="black" mt="6" fontSize="15" fontWeight={500}>
                {testimonialsContents[currentIndex].descComment2}
              </Box>
            ) : null}
          </Box>
        </Grid>
      </Box>
    </Box>
  )
}

export default HomeTestimonial
